function backPage(event: any) {
    event.preventDefault();
    window.history.back();
}

function init(container: HTMLElement | Document = document) {
    const backLink = document.querySelectorAll<HTMLElement>('.js-back-page');
    backLink.forEach((link) => {
        link.addEventListener('click', backPage);
    });
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-back-page')).forEach((el) => {
        el.addEventListener('click', backPage);
    });
}

const _module = { init, destroy };

export default _module;
