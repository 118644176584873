import gsap from 'gsap';
import ToHeaderTransition from './to-header';
import ToHeaderSetTransition from './to-header-set';
import hideContent from './to-leave';

function contentAnimation(next) {

    const tl = gsap.timeline();

    tl
        .fromTo(
            '.a-breadcrumbs',
            {
                y: 30,
                duration: 0.6,
                opacity: 0
            },
            {
                opacity: 1,
                y: 0
            }
        )
        .fromTo(
            '.a-title',
            {
                y: 30,
                duration: 0.6,
                stagger: 0.09,
                opacity: 0
            },
            {
                opacity: 1,
                y: 0
            },
            '-=0.3'
        )
        .fromTo(
            '.a-description',
            {
                duration: 0.5,
                opacity: 0,
                y: 30,
            },
            {
                opacity: 1,
                y: 0
            },
            '-=0.3',
        )
        .fromTo(
            '.a-navigation',
            {
                duration: 0.5,
                opacity: 0,
                x: -20,
            },
            {
                opacity: 1,
                x: 0
            },
            '-=0.4'
        )
        .fromTo(
            '.a-line-descr',
            {
                duration: 0.6,
                opacity: 0,
                y: 15,
            },
            {
                opacity: 1,
                y: 0
            },
            '-=0.5'
        )
        .fromTo(
            '.a-second-section',
            {
                duration: 0.6,
                opacity: 0,
                y: 15,
            },{
                opacity: 1,
                y: 0
            },
            '-=0.5',
        );
}

export default {
    name: 'to-accessories',
    to: {
        namespace: 'accessories-page',
    },

    async leave({current}) {
        const done = this.async();

        hideContent();
        await new Promise(resolve => setTimeout(resolve, 300));
        done();
    },

    async enter({current, next}) {
        contentAnimation(next);
        ToHeaderSetTransition(next);
    },

    afterEnter() {
        window.scrollTo({ top: 0, behavior: 'auto' });
    },

    async once({next}) {
        ToHeaderTransition(next);
        contentAnimation(next);
    },
}
