import gsap from 'gsap';
import barba from '@barba/core';
import { getOffsetTop } from '../utils/dom';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

const SELECTOR = '[data-scrollto]';

const header = document.querySelector('.js-header');
let elements: HTMLElement[] = [];

export async function scrollTo(el: Element) {
    let y = getOffsetTop(el) - (header?.getBoundingClientRect().height || 73);

    if (window.matchMedia('(max-width: 1280px)').matches) {
        y = getOffsetTop(el) - (header?.getBoundingClientRect().height || 13);
    }

    if (window.matchMedia('(max-width: 1024px)').matches) {
        y = getOffsetTop(el) - (header?.getBoundingClientRect().height || 73);
    }

    if (window.matchMedia('(max-width: 767px)').matches) {
        y = getOffsetTop(el) - (header?.getBoundingClientRect().height || 33);
    }

    gsap.registerPlugin(ScrollToPlugin);
    gsap.to(window, { duration: 0.7, scrollTo: y, ease: 'power2.inOut' });


}

async function onClick(this: HTMLElement, event: Event) {
    event.preventDefault();
    const selector = this.dataset.scrollto;

    if (!selector) {
        throw new Error(`[scrollto] ${SELECTOR} is missing on the element.`);
    }

    const elementScrollTo = document.querySelector(selector);

    if (elementScrollTo) {
        scrollTo(elementScrollTo);
    } else if (this instanceof HTMLAnchorElement && this.href) {
        barba.go(this.href);
    }
}

function init(container: Element | Document = document) {
    elements = Array.from(container.querySelectorAll<HTMLElement>(SELECTOR));

    elements.forEach((el) => {
        el.addEventListener('click', onClick);
    });

    if (window.location.hash) {
        setTimeout(() => {
            const selectId = window.location.hash.slice(1);
            const elementScrollTo = document.getElementById(selectId);

            if (elementScrollTo) {
                scrollTo(elementScrollTo);
            }
        }, 500);
    }
}

function destroy() {
    elements.forEach((el) => {
        el.removeEventListener('click', onClick);
    });

    elements = [];
}

const _module = { init, destroy };

export default _module;
