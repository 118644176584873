import zeroAdd from './addZero';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);


export default function navigation() {

    const sections = document.querySelectorAll("[data-title-section]");

    const navCurrent = document.querySelector(".js-nav-current-block");
    const navControllButtons = document.querySelector<HTMLElement>(".js-nav-controlls");
    const navigationBtns = document.getElementsByClassName('js-nav-controll');

    (function createNavButtons() {
        if (navControllButtons && sections.length === 1) {
            navControllButtons.style.display ='none';
        }

        if (navCurrent && navControllButtons && sections.length > 0) {
            for (let i = 0; i < sections.length; i++) {
                const btnCurrent = document.createElement('div');
                const btn = document.createElement('div');
                const titleSection = sections[i].getAttribute('data-title-section');
                btnCurrent.classList.add('right-nav__item-text');
                btnCurrent.classList.add('right-nav__item-text--current');
                btnCurrent.classList.add('js-nav-current-el');
                btnCurrent.innerHTML = `
                    <div class="nav-controll__up-block">
                        ${zeroAdd(i + 1)} / ${titleSection}
                    </div>
                `;
                navCurrent.append(btnCurrent);

                btn.classList.add('nav-controll');
                btn.classList.add('js-nav-controll');
                btn.innerHTML = `
                    <div class="nav-controll__icon right-nav__item-btn">
                        <?xml version="1.0" encoding="UTF-8"?>
                        <svg width="14px" height="7px" viewBox="0 0 14 7" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <!-- Generator: Sketch 58 (84663) - https://sketch.com -->
                            <title>Oval</title>
                            <desc>Created with Sketch.</desc>
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g transform="translate(-1862.000000, -887.000000)" fill="#9EAFBF">
                                    <g transform="translate(1839.000000, 63.000000)">
                                        <g transform="translate(2.000000, 638.000000)">
                                            <g transform="translate(28.000000, 197.500000) rotate(90.000000) translate(-28.000000, -197.500000) translate(-8.000000, 190.000000)">
                                                <path d="M35,11 C35,7.13400675 31.8659932,4 28,4 C24.1340068,4 21,7.13400675 21,11" transform="translate(28.000000, 7.500000) rotate(90.000000) translate(-28.000000, -7.500000) "></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div class="nav-controll__up-block-show">
                        <div class="nav-controll__up-block js-nav-next-el">
                            <div class="right-nav__item-text nav-controll__text-block">
                                <span class="nav-controll__number js-nav-number">${zeroAdd(i + 1)} / </span>
                                <span class="nav-controll__text js-nav-title"> ${titleSection} </span>
                            </div>
                            <div class="right-nav__item-arrow js-nav-arrow">
                                <svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.8889 1.05554L18 6.68518L11.8889 11.986" stroke="#0158A6" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M17.6685 6.52075L0.934278 6.52075" stroke="#0158A6" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                `;
                navControllButtons.append(btn);
            }
        }
    })();

    function addBullets() {
        const bulletsInner = document.createElement('div');
        bulletsInner.classList.add('trigger-navigation-inner');
        for (let i = 0; i < sections.length; i++) {
            const bulletTrigger = document.createElement('div');
            bulletTrigger.classList.add('trigger-navigation');
            bulletTrigger.innerHTML = `
            `;
            bulletsInner?.append(bulletTrigger);
        }
        navControllButtons?.append(bulletsInner);
    }
    addBullets();

    function navigation(i: number) {
        const navCurrentEl = document.querySelectorAll<HTMLElement>(".js-nav-current-el");
        const navNextEl = document.querySelectorAll<HTMLElement>(".js-nav-controll");
        const bulletTriggerEl = document.getElementsByClassName('trigger-navigation');
        const chipsaLogo = document.querySelector<HTMLElement>(".js-chipsa");
        let index = i + 1;

        if (index >= sections.length) {
            index = 0;
            chipsaLogo?.classList.add('is-show');
        } else {
            index = i + 1;
            chipsaLogo?.classList.remove('is-show');
        }

        if (navCurrentEl.length > 0 && navNextEl.length > 0) {
            navCurrentEl.forEach((item, index) => {
                item.classList.remove('is-active');
            });

            navCurrentEl[i]?.classList.add('is-active');

            navNextEl.forEach((item, indexItem) => {
                navigationBtns[indexItem].classList.remove('current-show');
                item.classList.remove('is-active');
                item.classList.remove('is-show');
                navigationBtns[indexItem].classList.remove('is-hover');
                item.style.display = "flex";

                item.addEventListener('click', () => {
                    scrollToSection();
                })

                if (bulletTriggerEl[indexItem]) {
                    bulletTriggerEl[indexItem].addEventListener('click', () => {
                        scrollToSection();
                    })
                }

                function scrollToSection() {
                    sections[indexItem].scrollIntoView({behavior: "smooth"});
                    navigation(indexItem);
                    navCurrentEl.forEach(item => {
                        item.classList.remove('is-active');
                    });
                    navNextEl.forEach(item => {
                        item.classList.remove('is-show');
                    })
                }
            })

            if (index === 0) {
                navNextEl[0].classList.add('is-active');
            } else {
                navNextEl[i]?.classList.add('is-active');
                navigationBtns[i].classList.add('current-show');
            }
        }
    }

    if (sections.length > 0) {
        sections.forEach((section, index) => {
            const observer = new IntersectionObserver(
                (entries, obs) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

                            if (scrollTop === 0) {
                                navigation(0);
                            } else {
                                navigation(index);
                            }
                        }
                    });
                },
                {
                    rootMargin: '-6% 0% 0% 0%',
                    threshold: [0],
                },
            );

            observer.observe(section);
        });
    }

    const navigationBullets = document.getElementsByClassName('trigger-navigation');
    for (let i = 0; i < navigationBtns.length; i++) {
        navigationBullets[i].addEventListener('mouseover', () => {
            if (!navigationBtns[i].classList.contains('is-active')) {
                const activeBtn = document.getElementsByClassName('js-nav-controll is-active')[0];
                activeBtn.classList.add('is-show');
                navigationBtns[i].classList.add('is-hover');
            }
        })
        navigationBullets[i].addEventListener('mouseout', () => {
            const activeBtn = document.getElementsByClassName('js-nav-controll is-active')[0];
            activeBtn.classList.remove('is-show');
            navigationBtns[i].classList.remove('is-hover');
        })
    }
}
