import gsap from 'gsap';

export default function hideContent() {

    const tl = gsap.timeline();

    tl.to(
        '.a-page-content',
        {
            duration: 0.3,
            opacity: 0
        }
    );
}
