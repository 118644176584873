let header: HTMLElement | null;
export function lockScroll () {
    header = document.querySelector<HTMLElement>('.js-header');
    const getScrollBarWidth = () => {
        const outer = document.createElement('div');

        outer.style.cssText = `
            position: absolute;
            top: -9999px;
            width: 50px;
            height: 50px;
            overflow: scroll;
            visibility: hidden;
        `;

        document.body.appendChild(outer);
        const scrollbarWidth = outer.offsetWidth - outer.clientWidth;
        document.body.removeChild(outer);

        return scrollbarWidth;
    }

    document.body.style.paddingRight = `${getScrollBarWidth()}px`;
    if (header) {
        header.style.paddingRight = `${getScrollBarWidth()}px`;
    }
    document.body.style.overflow = 'hidden';
    const htmlContainer = document.querySelector('html');
    // if (htmlContainer) htmlContainer.style.overflowY = 'hidden';
}

export function unlockScroll () {
    document.body.style.paddingRight = ``;
    if (header) {
        header.style.paddingRight = ``;
    }
    document.body.style.overflow = '';
    const htmlContainer = document.querySelector('html');
    if (htmlContainer) htmlContainer.style.overflow = '';
}
