// import gsap from 'gsap';
import barba from '@barba/core';
import popupCookie from '../inits/popup-cookie';

export function initBaseView() {

    barba.hooks.beforeEnter(() => {
        popupCookie.init();
    });

    barba.hooks.beforeLeave(() => {
        popupCookie.destroy();
    });
}
