export default function activeCharacteristic(previewIndex: number, realIndex: number, time: number) {
    const slideBlock = document.querySelectorAll('.js-solutions-slide');
    slideBlock.forEach((slide) => {
        const arrWrapperChar = slide.querySelectorAll('.js-wrapper-characteristic');

        arrWrapperChar[previewIndex]?.classList.add('is-out');
        setTimeout(() => {
            if (arrWrapperChar[previewIndex]) {
                arrWrapperChar[previewIndex].classList.remove('is-show');
                arrWrapperChar[previewIndex].classList.remove('is-out');
            }
        }, time / 4);

        setTimeout(() => {
            if (arrWrapperChar[realIndex]) {
                arrWrapperChar[realIndex].classList.add('is-in');
                arrWrapperChar[realIndex].classList.add('is-show');
                const itemCircle = arrWrapperChar[realIndex].querySelectorAll('.js-solutions-characteristic');

                arrWrapperChar.forEach((item) => {
                    item.querySelectorAll('.js-solutions-characteristic').forEach((el) => {
                        el.classList.remove('is-active');
                    });
                });

                itemCircle[0]?.classList.add('is-active');
            }
        }, time / 2);

        setTimeout(() => {
            arrWrapperChar[realIndex]?.classList.remove('is-in');
        }, time);
    });
}
