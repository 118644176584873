import barba from '@barba/core';

import { lockScroll, unlockScroll } from './custom-elements/lockScroll';
import { lazyload } from './custom-elements/lazy-load';
import backPage from './custom-elements/page-back';
import navigation from './custom-elements/navigation';
import reveal from './custom-elements/reveal';
import showPopup from './custom-elements/popup';
import form from './custom-elements/form';
import exitBtn from './custom-elements/exitBtn';
import scrollTo from './inits/scrollTo';
import draggable from './inits/draggable';
import vhMobileFix from './modules/vh-mobile-fix';


function clearForm() {
    const formEls = document.querySelectorAll<HTMLFormElement>('.js-form');
    formEls.forEach(el => {
        const inputsOfForm = el.querySelectorAll<HTMLInputElement>('.js-input');
        const argeeLabel = el.querySelector('.js-agree-label');

        el.reset();

        inputsOfForm.forEach(input => {
            input.classList.remove('is-not-empty');
            input.classList.remove('is-error');
        });

        if (argeeLabel) {
            argeeLabel.classList.remove('is-error');
        }
    })
}

barba.hooks.beforeEnter((data) => {
    backPage.init(data?.next.container);
    form();
    reveal.init();
    showPopup();
    lazyload.update();
    unlockScroll();
    exitBtn.init();
    vhMobileFix();
    draggable.init(data?.next.container);
    scrollTo.init(data?.next.container);
});

barba.hooks.after((data) => {
    navigation();
});

barba.hooks.afterOnce(() => {
    navigation();
});

barba.hooks.beforeLeave((data) => {
    backPage.destroy();
    exitBtn.destroy();
    scrollTo.destroy();
    clearForm();
    draggable.destroy(data?.current.container);
});

