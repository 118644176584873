import './webpack-imports';
import './sentry';
import './polyfills';

import barba from '@barba/core';

import { preloader } from './components/preloader';
import { timeout } from './utils/timeout';
import ToIndexTransition from './transitions/to-index';
import ToAboutTransition from './transitions/to-about';
import ToNewsTransition from './transitions/to-news';
import ToArticleTransition from './transitions/to-article';
import ToPartnersTransition from './transitions/to-partners';
import ToDocumentsTransition from './transitions/to-documents';
import ToContactsTransition from './transitions/to-contacts';
import ToCatalogTransition from './transitions/to-catalog';
import ToCardTransition from './transitions/to-card';
import ToSearchTransition from './transitions/to-search';
import ToDocumentationTransition from './transitions/to-documentation';
import ToApplicationsTransition from './transitions/to-applications';
import ToAccessoriesTransition from './transitions/to-accessories';
import lazyView from './views/_lazy';
import indexSlider from './inits/index-slider';
import slider from './inits/slider';
import activeCharacteristic from './inits/solutionsChar';
import activeSemicircles from './inits/active-semicircles';
import { initBaseView } from './views/_base';

import { registerCustomElements } from './custom-elements';
import './barba-global';

document.addEventListener('DOMContentLoaded', () => {
    setTimeout(() => {
        document.documentElement.classList.add('js-ready');
    }, 1000);

    registerCustomElements();
    initBaseView();

    const indexSliderContainer = document.querySelector('.js-index-slider');
    if (indexSliderContainer) {
        indexSlider.init();
        activeCharacteristic.init();
        activeSemicircles();
        slider.init();
    }
});

preloader
    .loadAssets()
    .then(() => timeout(1600))
    .then(() => preloader.leave())
    .then(() => {
        barba.init({
            transitions: [
                ToIndexTransition,
                ToAboutTransition,
                ToNewsTransition,
                ToArticleTransition,
                ToPartnersTransition,
                ToDocumentsTransition,
                ToContactsTransition,
                ToCatalogTransition,
                ToCardTransition,
                ToSearchTransition,
                ToDocumentationTransition,
                ToApplicationsTransition,
                ToAccessoriesTransition,
            ],
            views: [
                lazyView('index-page', () =>
                    import(/* webpackChunkName: "index-page", webpackPrefetch: true */ './views/index-page').then(
                        (m) => m.default,
                    ),
                ),
                lazyView('about-page', () =>
                    import(/* webpackChunkName: "about-page" */ './views/about-page').then((m) => m.default),
                ),
                lazyView('partners-page', () =>
                    import(/* webpackChunkName: "partners-page" */ './views/partners-page').then((m) => m.default),
                ),
                lazyView('documents-page', () =>
                    import(/* webpackChunkName: "documents-page" */ './views/documents-page').then((m) => m.default),
                ),
                lazyView('news-page', () =>
                    import(/* webpackChunkName: "news-page" */ './views/news-page').then((m) => m.default),
                ),
                lazyView('catalog-page', () =>
                    import(/* webpackChunkName: "catalog-page", webpackPrefetch: true */ './views/catalog-page').then(
                        (m) => m.default,
                    ),
                ),
                lazyView('card-page', () =>
                    import(/* webpackChunkName: "card-page" */ './views/card-page').then((m) => m.default),
                ),
                lazyView('search-page', () =>
                    import(/* webpackChunkName: "search-page" */ './views/search-page').then((m) => m.default),
                ),
                lazyView('contacts-page', () =>
                    import(/* webpackChunkName: "contacts-page" */ './views/contacts-page').then((m) => m.default),
                ),
                lazyView('documentation-page', () =>
                    import(/* webpackChunkName: "contacts-page" */ './views/documentation-page').then((m) => m.default),
                ),
                lazyView('applications-page', () =>
                    import(/* webpackChunkName: "contacts-page" */ './views/applications-page').then((m) => m.default),
                ),
                lazyView('accessories-page', () =>
                    import(/* webpackChunkName: "contacts-page" */ './views/accessories-page').then((m) => m.default),
                ),
            ],
            debug: false,
            prefetchIgnore: true,
            preventRunning: true,
            prevent: ({ el }) => el.tagName === 'A' && !!el.closest('#bx-panel'),
            timeout: 5000,
        });
    });
