const observer = new IntersectionObserver(
    (entries, obs) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                obs.unobserve(entry.target);
                entry.target.classList.add('reveal--animated');
            }
        });
    },
    {
        threshold: 0.25,
    },
);

function init(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLElement>('.js-reveal'));
    elements.forEach((el) => {
        observer.observe(el);
    });
}

function destroy(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLElement>('.js-reveal'));
    elements.forEach((el) => {
        observer.unobserve(el);
    });
}

const _module = { init, destroy };

export default _module;
