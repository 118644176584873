import debounce from 'lodash.debounce';

export default () => {

    const vh = window.innerHeight;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    const calculateVhOnResize = debounce(() => {
        if (!window.matchMedia('(max-width: 1024px)').matches) {
            return;
        }

        const newVh = window.innerHeight;
        document.documentElement.style.setProperty('--vh', `${newVh}px`);
    }, 100);

    const calculateVhOnOrientationChange = debounce(() => {
        if (!window.matchMedia('(max-width: 1024px)').matches) {
            return;
        }

        const newVh = window.innerHeight;
        document.documentElement.style.setProperty('--vh', `${newVh}px`);
    }, 50);

    window.addEventListener('resize', calculateVhOnResize);
    window.addEventListener('orientationchange', calculateVhOnOrientationChange);

    return () => {
        window.removeEventListener('resize', calculateVhOnResize);
        window.removeEventListener('orientationchange', calculateVhOnOrientationChange);
    };
};
