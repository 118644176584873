let itemCircle: NodeListOf<Element>;

function onMouseOver(this: HTMLElement) {
    if (!this.classList.contains('is-active')) {
        itemCircle.forEach((i) => {
            i.classList.remove('is-active');
        });
        this.classList.add('is-active');
    }
}

function init(container: HTMLElement | Document = document) {
    const slideBlock = container.querySelectorAll('.js-solutions-slide');

    slideBlock.forEach((slide) => {
        itemCircle = slide.querySelectorAll('.js-solutions-characteristic');
        itemCircle[0]?.classList.add('is-active');

        itemCircle.forEach((item) => {
            item.addEventListener('mouseover', onMouseOver);
        });
    });
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-solutions-characteristic')).forEach((el) => {
        if (el) {
            el.removeEventListener('mouseover', onMouseOver);
        }
    });
}

const _module = { init, destroy };

export default _module;
