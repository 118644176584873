import gsap from 'gsap';
import { CountUp } from 'countup.js';
import barba from '@barba/core';

export const preloaderAnimationDuration = 0.7; // sec

function createPreloader() {
    const preloader = document.querySelector<HTMLElement>('.js-preloader');
    const counterContainer = document.querySelector<HTMLElement>('.js-preloader-counter-container');
    const counter = document.querySelector<HTMLElement>('.js-preloader-counter');
    const circle = document.querySelector<HTMLElement>('.js-preloader-circle');
    const circleBg = document.querySelector<HTMLElement>('.js-preloader-circle-bg');
    const circleBg2 = document.querySelector<HTMLElement>('.js-preloader-circle-bg-2');
    let loaded = false;

    const state = {
        completed: false,
    };

    document.body.classList.add('no-scroll');

    function leave(): Promise<void> {
        return new Promise((resolve) => {
            document.body.classList.remove('no-scroll');

            document.dispatchEvent(new Event('preloader-leave'));

            barba.hooks.afterEnter(() => {
                if (!loaded) {
                    loaded = true;
                }
            });

            const tl = gsap.timeline();

            tl.to(circleBg2, { duration: 0.4, xPercent: -100 })
                .to(counterContainer, { duration: 0.6, opacity: 0 })
                .to(preloader, {
                    duration: 0.5,
                    opacity: 0,
                    onComplete: () => {
                        preloader?.classList.add('is-hide');
                        state.completed = true;
                    },
                })
                .add(resolve, 1.8);
        });
    }

    function loadAsset(asset: HTMLImageElement | HTMLVideoElement | HTMLCanvasElement): Promise<void> {
        return new Promise((resolve) => {
            if (asset instanceof HTMLImageElement) {
                if (asset.complete) {
                    resolve();
                } else {
                    asset.onload = () => resolve();
                    asset.onerror = () => resolve();
                }
            }

            if (asset instanceof HTMLVideoElement) {
                if (asset.readyState === 4) {
                    resolve();
                } else {
                    asset.addEventListener('canplay', () => resolve(), { once: true });
                }
            }

            if (asset instanceof HTMLCanvasElement) {
                if (asset.classList.contains('is-loaded')) {
                    resolve();
                } else {
                    document.addEventListener(
                        'model.loaded',
                        () => {
                            resolve();
                        },
                        { once: true },
                    );
                }
            }
        });
    }

    const counterInstance = counter
        ? new CountUp(counter, 0, {
              startVal: 0,
              useEasing: true,
              duration: 1.05,
          })
        : null;

    let firstTimeOut: ReturnType<typeof setTimeout>;
    let secondTimeOut: ReturnType<typeof setTimeout>;
    let thirdTimeOut: ReturnType<typeof setTimeout>;
    let fourthTimeOut: ReturnType<typeof setTimeout>;
    let fifthTimeOut: ReturnType<typeof setTimeout>;
    let sexthTimeOut: ReturnType<typeof setTimeout>;
    let seventhTimeOut: ReturnType<typeof setTimeout>;

    function setPercent() {
        const tl = gsap.timeline({
            defaults: {
                duration: 0.1,
            },
        });

        setTimeout(() => {
            tl.to(circleBg, {
                xPercent: -30,
                duration: 0.2,
            }).to(
                circle,
                {
                    scale: 0.5,
                },
                0,
            );
        }, 100);
        counterInstance?.update(30);
        setTimeout(() => {
            tl.to(circleBg, {
                xPercent: -70,
                duration: 0.2,
            }).to(
                circle,
                {
                    scale: 0.8,
                },
                0,
            );
            counterInstance?.update(70);
        }, 300);
        setTimeout(() => {
            tl.to(circleBg, {
                xPercent: -100,
                duration: 0.8,
            }).to(
                circle,
                {
                    scale: 1,
                    duration: 0.8,
                },
                0,
            );
            counterInstance?.update(100);
        }, 400);

        clearTimeout(firstTimeOut);
        clearTimeout(secondTimeOut);
        clearTimeout(thirdTimeOut);
        clearTimeout(fourthTimeOut);
        clearTimeout(fifthTimeOut);
        clearTimeout(sexthTimeOut);
        clearTimeout(seventhTimeOut);
    }

    function setFirstPercents() {
        const tl = gsap.timeline({
            defaults: {
                duration: 0.1,
            },
        });

        firstTimeOut = setTimeout(() => {
            tl.to(circleBg, {
                xPercent: -10,
                duration: 0.2,
            }).to(
                circle,
                {
                    scale: 0.4,
                },
                0,
            );
            counterInstance?.update(10);
        }, 100);

        secondTimeOut = setTimeout(() => {
            tl.to(circleBg, {
                xPercent: -13,
                duration: 0.2,
            }).to(
                circle,
                {
                    scale: 0.5,
                },
                0,
            );
            counterInstance?.update(13);
        }, 500);

        thirdTimeOut = setTimeout(() => {
            tl.to(circleBg, {
                xPercent: -17,
                duration: 0.2,
            }).to(
                circle,
                {
                    scale: 0.55,
                },
                0,
            );
            counterInstance?.update(17);
        }, 1200);

        fourthTimeOut = setTimeout(() => {
            tl.to(circleBg, {
                xPercent: -21,
                duration: 0.2,
            }).to(
                circle,
                {
                    scale: 0.6,
                },
                0,
            );
            counterInstance?.update(21);
        }, 2000);

        fifthTimeOut = setTimeout(() => {
            tl.to(circleBg, {
                xPercent: -23,
                duration: 0.2,
            }).to(
                circle,
                {
                    scale: 0.63,
                },
                0,
            );
            counterInstance?.update(23);
        }, 3000);

        sexthTimeOut = setTimeout(() => {
            tl.to(circleBg, {
                xPercent: -25,
                duration: 0.2,
            }).to(
                circle,
                {
                    scale: 0.65,
                },
                0,
            );
            counterInstance?.update(25);
        }, 4000);

        seventhTimeOut = setTimeout(() => {
            tl.to(circleBg, {
                xPercent: -30,
                duration: 0.2,
            }).to(
                circle,
                {
                    scale: 0.7,
                },
                0,
            );
            counterInstance?.update(30);
        }, 5200);
    }

    async function loadAssetsFromElement(element: Element | Document = document) {
        const images = Array.from(element.querySelectorAll<HTMLImageElement>('img:not(.lazy):not([loading="lazy"])'));
        const videos = Array.from(
            element.querySelectorAll<HTMLVideoElement>('video.js-autoplay:not(.lazy):not([loading="lazy"])'),
        );
        const models = Array.from(element.querySelectorAll<HTMLCanvasElement>('.js-canvas-main-model'));
        const assets: Array<HTMLImageElement | HTMLVideoElement | HTMLCanvasElement> = [
            ...models,
            ...images,
            ...videos,
        ];
        setFirstPercents();

        if (images.length > 0 || videos.length > 0 || models.length > 0) {
            await Promise.all<any>(assets.map((asset) => loadAsset(asset)));
        }
    }

    async function loadAssets() {
        await loadAssetsFromElement(document.body);
        setPercent();
    }

    return { leave, loadAssets, state } as const;
}

export const preloader = createPreloader();
