export default function activeSemicircles() {
    const mainContainer = document.querySelectorAll('[data-semicircle-container]');

    mainContainer.forEach(block => {
        const semicircles = block.querySelectorAll<HTMLElement>('.js-semicircle');
        const contentElements = block.querySelectorAll<HTMLElement>('[data-text-content]');

        if (contentElements.length === 1) {
            semicircles.forEach(item => {
                const semicirclesContainer = item.closest<HTMLElement>('.js-swiper-text-slider-bullets');
                if (semicirclesContainer) semicirclesContainer.style.display = "none";
            });
        }

        if(contentElements.length > 0) {
            contentElements.forEach((item, i) => {
                if (item.classList.contains('is-active')) {
                    semicircles[i].classList.add('is-active');
                } else {
                    semicircles[i].classList.remove('is-active');
                }
            });
        }
    })
}
