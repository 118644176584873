/* eslint-disable max-classes-per-file, max-len, max-lines */
/* global PUBLIC_PATH */
import gsap from 'gsap';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader';
// import { ObjectControls } from 'threejs-object-controls';
import { triggerCustomEvent } from '../../utils/trigger-events';
import { makeSilicone } from './materials/SiliconeMaterial';
import { makePlastic } from './materials/PlasticMaterial';
import { withLeadingZero } from '../../utils/strings';

let count = 0;

class Model {
    constructor(
        el,
        state,
        loadingManager,
        textureLoader,
        renderer,
        scene,
        camera,
        sizes,
        animationDuration,
        raycaster,
        canvas,
        setActive = true,
        gui,
        onControlsChange,
    ) {
        this.onResize = this.onResize.bind(this);
        this.observePoints = this.observePoints.bind(this);
        this.showModel = this.showModel.bind(this);
        this.name = `Model-${++count}`;
        this.el = el;
        this.state = state;
        this.canvas = canvas;
        this.onControlsChange = onControlsChange;
        this.meta = el.dataset.meta;
        this.model = el.dataset.model;
        this.modelGroup = new THREE.Group();
        this.initialModelGroupY = this.modelGroup.position.y;
        this.animationDuration = animationDuration;
        this.isOutTimer = undefined;
        this.oldAnchor = undefined;
        this.wrapGroup = new THREE.Group();
        this.wrapGroup.visible = false;
        this.loadingManager = loadingManager;
        this.textureLoader = textureLoader;
        this.renderer = renderer;
        this.scene = scene;
        this.siliconeRoughnessTexture = this.textureLoader.load(`${PUBLIC_PATH}img/maps/Roughness-256.jpg`);
        this.siliconeRoughnessTexture.wrapS = THREE.RepeatWrapping;
        this.siliconeRoughnessTexture.wrapT = THREE.RepeatWrapping;
        this.siliconeBumpTexture = this.textureLoader.load(`${PUBLIC_PATH}img/maps/Bump-256.jpg`);
        this.siliconeBumpTexture.wrapS = THREE.RepeatWrapping;
        this.siliconeBumpTexture.wrapT = THREE.RepeatWrapping;
        this.gui = gui !== undefined ? gui : undefined;

        this.siliconeMaterialGui = this.gui !== undefined ? this.gui.addFolder(`Silicone-${this.meta}`) : undefined;
        this.plasticMaterialGui = this.gui !== undefined ? this.gui.addFolder(`Plastic-${this.meta}`) : undefined;

        this.time = 0;
        this._inlinePaginationCounter = 0;

        this.isModelReady = false;
        this.camera = camera;
        this.sizes = sizes;

        this.isInited = false;
        this.active = setActive;
        this.isLoaded = false;

        this.checkVisibilityInterval = undefined;

        this.raycaster = raycaster;

        this.sceneEls = undefined;
        this.siliconeEls = [];
        this.plastEls = [];
        this.bufferSiliconeEls = [];
        this.raycastableObjects = [];

        this.gltfLoader = new GLTFLoader(this.loadingManager);
        this.dracoLoader = new DRACOLoader(this.loadingManager);
        this.dracoLoader.setDecoderPath(`${PUBLIC_PATH}draco/`);
        this.gltfLoader.setDRACOLoader(this.dracoLoader);
        this.ktx2Loader = new KTX2Loader(this.loadingManager)
            .setTranscoderPath(`${PUBLIC_PATH}basis/`)
            .detectSupport(this.renderer);
        this.gltfLoader.setKTX2Loader(this.ktx2Loader);

        this.handVec = new THREE.Vector3();
        this.worldPos = new THREE.Vector3();

        // this.spriteMap = this.textureLoader.load(`${PUBLIC_PATH}img/textures/spriteMap.png`);
        // this.spriteMap.encoding = THREE.sRGBEncoding;
        // this.spriteAlphaMap = this.textureLoader.load(`${PUBLIC_PATH}img/textures/spriteAlphaMap.jpg`);
        // this.spriteDisableMap = this.textureLoader.load(`${PUBLIC_PATH}img/textures/spriteDisableMap.png`);
        // this.spriteDisableAlphaMap = this.textureLoader.load(`${PUBLIC_PATH}img/textures/spriteDisableAlphaMap.jpg`);

        this.spriteMaterial = {
            // map: this.spriteMap,
            color: 0xffffff,
            // sizeAttenuation: false,
            transparent: true,
            opacity: 0,
            // premultipliedAlpha: true,
            // alphaMap: this.spriteDisableAlphaMap,
            // alphaTest: 0.5,
        };

        this.pmremGenerator = new THREE.PMREMGenerator(this.renderer);

        this.observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        this.observer.unobserve(entry.target);

                        if (this.active) {
                            this.setInitialModelState();
                        }
                    }
                });
            },
            { threshold: 0.5 },
        );

        window.addEventListener('resize', this.onResize);
    }

    generateSemicircleHtml(name) {
        const str = `
        <div class="btn-semicircle__item js-semicircle" data-anchor-semicircle="${name}">
            <svg width="14px" height="7px" viewBox="0 0 14 7" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>Oval</title>
                <desc>Created with Sketch.</desc>
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(-1862.000000, -887.000000)" fill="#9EAFBF">
                        <g transform="translate(1839.000000, 63.000000)">
                            <g transform="translate(2.000000, 638.000000)">
                                <g transform="translate(28.000000, 197.500000) rotate(90.000000) translate(-28.000000, -197.500000) translate(-8.000000, 190.000000)">
                                    <path d="M35,11 C35,7.13400675 31.8659932,4 28,4 C24.1340068,4 21,7.13400675 21,11" transform="translate(28.000000, 7.500000) rotate(90.000000) translate(-28.000000, -7.500000) "></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
        `;
        return str;
    }

    generateAnchorHtml(name) {
        return `
            <button class="anchor__button js-anchor" data-anchor-name="${name}">
                <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.87017 4.53397H0.79817V3.30197H3.87017V0.00596619H5.15017V3.30197H8.22217V4.53397H5.15017V7.82997H3.87017V4.53397Z" fill="white"/>
                </svg>
            </button>
        `;
    }

    generateInlineAnchorHtml(name) {
        return `
            <button class="product-card__tab-item js-product-card-3d-control" data-anchor-name="${name}">${withLeadingZero(
            ++this._inlinePaginationCounter,
        )} /</button>
        `;
    }

    createAnchor(name) {
        const semicircleWrapper = this.el.querySelector('.js-slider-semicircles');
        const anchorsWrapper = this.el.querySelector('.js-anchors-wrapper');
        const anchorsWrapperInline = this.el.querySelector('.js-anchors-wrapper--inline');

        if (semicircleWrapper) {
            const semicircleDiv = document.createElement('div');
            semicircleDiv.innerHTML = this.generateSemicircleHtml(name);
            semicircleWrapper.appendChild(semicircleDiv);
        }

        if (anchorsWrapper) {
            const anchorDiv = document.createElement('div');
            anchorDiv.innerHTML = this.generateAnchorHtml(name);
            anchorsWrapper.appendChild(anchorDiv);
        }

        if (anchorsWrapperInline) {
            const anchorDiv = document.createElement('div');
            anchorDiv.innerHTML = this.generateInlineAnchorHtml(name);
            anchorsWrapperInline.appendChild(anchorDiv);
        }
    }

    setActiveAnchor(anchor, immediate = false) {
        const indexOfCurrentAnchor = this.anchors.indexOf(anchor);
        this.anchors.forEach((anchorOfModel, i) => {
            anchorOfModel.isActive = i === indexOfCurrentAnchor;
        });

        this.onAnchorChange(anchor, immediate);
    }

    disableClicks(anchor) {
        anchor.element?.classList.add('is-anchor-disable');
        anchor.semicircle?.classList.add('is-anchor-disable');
        anchor.content?.classList.add('is-anchor-disable');
    }

    enableClicks(anchor) {
        anchor.element?.classList.remove('is-anchor-disable');
        anchor.semicircle?.classList.remove('is-anchor-disable');
        anchor.content?.classList.remove('is-anchor-disable');
    }

    onAnchorChange(anchor, immediate = false) {
        // if (anchor.element.classList.contains('is-active')) return;

        const { cameraX, cameraY, cameraZ } = anchor;

        triggerCustomEvent(document, 'rotate.camera', {
            // modelName: this.name,
            immediate,
            coords: { x: cameraX, y: cameraY, z: cameraZ },
        });
        triggerCustomEvent(this.el, 'rotation-change', { anchorName: anchor.name });
        this.anchors.forEach((modelAnchor) => {
            this.disableClicks(modelAnchor);
            modelAnchor.element?.classList.remove('is-active');
            modelAnchor.inlineElement?.classList.remove('is-active');
        });
        clearTimeout(this.isOutTimer);

        anchor.element?.classList.add('is-active');
        anchor.inlineElement?.classList.add('is-active');

        anchor.semicircle?.classList.add('is-active');

        if (this.oldAnchor && this.oldAnchor !== anchor) {
            this.oldAnchor.content?.classList.add('is-out');
            this.oldAnchor.semicircle?.classList.remove('is-active');
        }

        this.isOutTimer = setTimeout(() => {
            if (this.oldAnchor && this.oldAnchor !== anchor) {
                this.oldAnchor.content?.classList.remove('is-out');
                this.oldAnchor.content?.classList.remove('is-active');
            }
            anchor.content?.classList.add('is-active');

            this.anchors.forEach((item) => {
                this.enableClicks(item);
            });
            this.oldAnchor = anchor;
        }, this.animationDuration / 2);
    }

    setInitialModelState(immediate = false) {
        if (this.anchors[0]) {
            this.setActiveAnchor(this.anchors[0], immediate);
        } else {
            this.rotateDefault(immediate);
        }
    }

    loadMetaData() {
        return new Promise((resolve) => {
            fetch(this.meta, {})
                .then((response) => response.json())
                .then((response) => {
                    if (!this.anchors) {
                        this.groupPosition = response.groupPosition;
                        this.groupRotation = response.groupRotation;
                        this.groupScale = response.groupScale;
                        this.cameraDefaultPositionX = response.cameraDefaultPositionX;
                        this.cameraDefaultPositionY = response.cameraDefaultPositionY;
                        this.cameraDefaultPositionZ = response.cameraDefaultPositionZ;
                        this.anchors = response.anchors;
                        // Генерируем якоря (плюсы на модели + буллеты справа вверху), вешаем обработчики
                        if (this.anchors && this.anchors.length > 0) {
                            this.anchors.forEach((anchor, index) => {
                                this.generateAnchor(anchor, index);
                                this.setAnchorHandlers(anchor);
                            });
                        }
                        // Делаем первый якорь активным
                        this.observer.observe(this.canvas);
                    }
                    resolve(response);
                });
        });
    }

    loadModel() {
        return new Promise((resolve) => {
            const afterLoad = (scene, texture) => {
                // const scale = 2.35;
                const scale = 3;
                scene.position.y += 2.5;
                scene.scale.set(scale, scale, scale);
                this.sceneEls = scene;

                if (this.model.includes('mainstream-co2-sensor-qure')) {
                    scene.position.y -= 5;
                    scene.scale.set(4, 4, 4);
                }

                if (this.model.includes('multigas-module') || this.model.includes('sidestream-co2-sensor')) {
                    scene.position.y -= 5;
                    scene.scale.set(2, 2, 2);
                }

                this.sceneEls.traverse((child) => {
                    if (child.isMesh) {
                        child.geometry.computeBoundsTree();

                        // console.log(child.name);

                        if (this.model.includes('sidestream-co2-sensor')) {
                            const newMaterial = child.material.clone();
                            newMaterial.map = texture;
                            child.material = newMaterial;
                        }

                        if (child.material.name !== 'Silicon') {
                            if (texture) {
                                texture.flipY = false;
                                texture.encoding = THREE.sRGBEncoding;
                            }
                            // if (child.material.name !== 'DefaultMaterial') {
                            //     const newMaterial = new THREE.MeshBasicMaterial({
                            //         map: texture || child.material.map,
                            //         side: THREE.DoubleSide,
                            //     });
                            //     child.material.dispose();
                            //     child.material = newMaterial;
                            // } else {
                            // child.material.envMapIntensity = 0.5;
                            // }
                        } else {
                            if (!this.siliconeMaterial) {
                                this.siliconeMaterial = new THREE.MeshPhysicalMaterial();

                                makeSilicone(this.siliconeMaterial, {
                                    roughnessMap: this.siliconeRoughnessTexture,
                                    bumpMap: this.siliconeBumpTexture,
                                });
                            }

                            child.material.dispose();
                            child.material = this.siliconeMaterial;
                        }

                        child.material.side = THREE.DoubleSide;
                        child.material.envMapIntensity = 2.5;
                    }
                });
                this.plastEls = scene.children.filter((child) => child.name.includes('plastic-'));

                this.modelGroup.add(this.sceneEls);
                this.wrapGroup.add(this.modelGroup);
                this.scene.add(this.wrapGroup);
                this.wrapGroup.position.set(...this.groupPosition.split(', '));
                this.wrapGroup.rotation.set(...this.groupRotation.split(', '));
                this.wrapGroup.scale.set(...this.groupScale.split(', '));

                resolve();
            };

            if (this.model.includes('sidestream-co2-sensor')) {
                if (window.multigasModuleClone) {
                    this.loadMetaData().then(() => {
                        afterLoad(
                            window.multigasModuleClone.clone(),
                            this.textureLoader.load(`${PUBLIC_PATH}img/textures/blue-model-4.png`),
                        );
                    });
                }
            } else {
                this.gltfLoader.load(this.model, (gltf) => {
                    this.dracoLoader.dispose();
                    this.ktx2Loader.dispose();

                    if (this.model.includes('multigas-module')) {
                        window.multigasModuleClone = gltf.scene;
                    }

                    afterLoad(gltf.scene);
                });
            }
        });
    }

    load() {
        return Promise.all([this.loadModel(), this.loadMetaData()]).then(() => {
            // for (let i = 0; i < this.anchors.length; i++) {
            //     const anchor = this.anchors[i];
            //     anchor.element.classList.add('is-visible');
            // }
            this.observePoints();
            this.observeVisibility();
            this.isModelReady = true;
            this.isLoaded = true;
        });
    }

    setAnchorHandlers(anchor) {
        const el = anchor.element;
        const { semicircle } = anchor;
        el?.addEventListener('click', () => {
            this.setActiveAnchor(anchor);
        });
        semicircle?.addEventListener('click', () => {
            this.setActiveAnchor(anchor);
        });
    }

    generateAnchor(anchor, index) {
        this.createAnchor(anchor.name);
        anchor.element = this.el.querySelector(`.js-anchor[data-anchor-name="${anchor.name}"]`);
        anchor.inlineElement = this.el.querySelector(`.js-product-card-3d-control[data-anchor-name="${anchor.name}"]`);
        anchor.position = new THREE.Vector3(anchor.positionX, anchor.positionY, anchor.positionZ);
        anchor.semicircle = this.el.querySelector(`[data-anchor-semicircle="${anchor.name}"]`);
        anchor.content = this.el.querySelector(`[data-anchor-content="${anchor.name}"]`);
        anchor.isActive = anchor.element.classList.contains('is-active');

        anchor.obj = new THREE.Sprite(new THREE.SpriteMaterial(this.spriteMaterial));
        anchor.obj.scale.set(0.1, 0.1, 0.1);
        anchor.obj.name = `vector-${index}`;
        anchor.obj.position.copy(anchor.position);
        this.modelGroup.add(anchor.obj);
    }

    observeVisibility() {
        this.checkVisibilityInterval = setInterval(() => {
            if (/* this.animatingModel ||  */ !(this.wrapGroup.visible && this.state.isInViewport)) return;

            // const objects = [
            //     ...this.modelGroup.children.filter((el) => el instanceof THREE.Sprite),
            //     ...this.sceneEls.children,
            // ];

            for (let i = 0; i < this.anchors.length; i++) {
                const anchor = this.anchors[i];
                anchor.obj.getWorldPosition(this.handVec);
                this.handVec.project(this.camera);
                this.raycaster.setFromCamera(this.handVec, this.camera);
                // const intersects = this.raycaster.intersectObjects(objects, false);
                const intersects = this.raycaster.intersectObjects(this.modelGroup.children, true);
                const show = intersects.length && anchor.obj === intersects[0].object;
                if (!show || Math.abs(this.handVec.z) > 1) {
                    anchor.element.classList.remove('is-visible');
                } else {
                    anchor.element.classList.add('is-visible');
                }
            }
        }, 350);
    }

    unOnserveVisibility() {
        clearInterval(this.checkVisibilityInterval);
    }

    tick() {
        this.time += 0.005;

        if (this.isModelReady) {
            if (this.siliconeMaterial?.customUniforms) {
                this.siliconeMaterial.customUniforms.uTime.value = this.time * 50;
            }

            for (let i = 0; i < this.anchors.length; i++) {
                const anchor = this.anchors[i];
                anchor.obj.getWorldPosition(this.worldPos);
                const screenPosition = this.worldPos;
                screenPosition.project(this.camera);

                const translateX = screenPosition.x * this.sizes.width * 0.5;
                const translateY = screenPosition.y * this.sizes.height * -0.5;
                anchor.element.style.transform = `translate(${translateX}px, ${translateY}px) rotate(-45deg)`;
                anchor.obj.updateWorldMatrix(true, false);
            }
        }
    }

    observePoints() {
        if (this.wrapGroup.visible && this.state.isInViewport) {
            this.tick();
        }

        this.rAF = requestAnimationFrame(this.observePoints);
    }

    init() {
        return new Promise((resolve) => {
            // this.loadMetaData().then(() => {
            //     // Генерируем якоря (плюсы на модели + буллеты справа вверху), вешаем обработчики
            //     if (this.anchors && this.anchors.length > 0) {
            //         this.anchors.forEach((anchor, index) => {
            //             this.generateAnchor(anchor, index);
            //             this.setAnchorHandlers(anchor);
            //         });
            //     }
            //     // Делаем первый якорь активным
            //     // this.setActiveAnchor(this.anchors[0]);
            // });
            this.isInited = true;
            resolve();
        });
    }

    destroy() {
        cancelAnimationFrame(this.rAF);
        window.removeEventListener('resize', this.onResize);
    }

    onResize() {
        this.tick();
    }

    animationModelOut() {
        return new Promise((resolve) => {
            this.animatingModel = true;

            const tl = gsap.timeline({
                onUpdate: this.onControlsChange,
                defaults: {
                    duration: this.animationDuration / 1000,
                    ease: 'sine.inOut',
                },
                onComplete: () => {
                    this.animatingModel = false;
                    resolve();
                },
            });

            this.anchors.forEach((anchor) => {
                anchor.element.classList.add('is-leaving');
            });

            tl.fromTo(
                this.modelGroup.position,
                { y: this.initialModelGroupY },
                {
                    y: this.initialModelGroupY + 1,
                },
                0,
            ).to(
                this.canvas,
                {
                    opacity: 0,
                },
                0,
            );
        });
    }

    animationModelIn() {
        this.animatingModel = true;

        return new Promise((resolve) => {
            const tl = gsap.timeline({
                onUpdate: this.onControlsChange,
                defaults: {
                    duration: this.animationDuration / 1000,
                    ease: 'sine.inOut',
                },
                onComplete: () => {
                    this.anchors.forEach((anchor) => {
                        anchor.element.classList.remove('is-leaving');
                    });

                    this.animatingModel = false;
                    resolve();
                },
            });

            this.setInitialModelState(true);

            tl.fromTo(
                this.modelGroup.position,
                { y: this.initialModelGroupY - 1 },
                {
                    y: this.initialModelGroupY,
                },
                0,
            ).to(
                this.canvas,
                {
                    opacity: 1,
                },
                0,
            );
        });
    }

    rotateDefault(immediate = false) {
        triggerCustomEvent(document, 'rotate.camera', {
            immediate,
            coords: {
                x: this.cameraDefaultPositionX,
                y: this.cameraDefaultPositionY,
                z: this.cameraDefaultPositionZ,
            },
        });
    }

    showModel() {
        this.wrapGroup.visible = true;
        this.animationModelIn();
    }

    hideModel() {
        this.animationModelOut().then(() => {
            this.wrapGroup.visible = false;
        });
    }
}

export default Model;
