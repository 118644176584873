import IMask from 'imask';
import { lockScroll, unlockScroll } from './lockScroll';
import fetchValidator from '../../modules/validator';

function removeProductInput (formContainer: HTMLFormElement) {
    if (formContainer) {
        const input = formContainer.querySelector('[name="product_name"]');
        input?.remove();
    }
}

export default function form() {
    const forms = Array.from(document.querySelectorAll('.js-form')) as HTMLFormElement[];

    forms.forEach((form) => {
        const formContainer = form.closest('.js-form-container');
        const urlForm = form.action;
        const inputsOfForm = form.querySelectorAll<HTMLInputElement>('.js-input');
        const requireInputs = form.querySelectorAll<Element | any>('.js-required');
        const argeeInput = form.querySelector<Element | any>('.js-agree-input');
        const argeeLabel = form.querySelector('.js-agree-label');
        const messageContainer = form.querySelector('.js-message-container');
        const inputMrRobot = form.querySelector<HTMLInputElement>('.js-form__check-val');

        // если инпут заполнен, лэйбел находится наверху
        inputsOfForm.forEach((input) => {
            input.addEventListener('change', () => {
                if (input.value.length >= 1) {
                    input.classList.add('is-not-empty');
                } else {
                    input.classList.remove('is-not-empty');
                }
            });
        });

        if (argeeInput && argeeLabel) {
            argeeInput.addEventListener('change', () => {
                if (!argeeInput.checked) {
                    argeeLabel.classList.add('is-error');
                } else {
                    argeeLabel.classList.remove('is-error');
                }
            });
        }

        const validator = fetchValidator(form);

        form.addEventListener('submit', (e) => {
            e.preventDefault();

            const isFormValid = validator.validate();

            // Проверяем все ли инпуты заполнены
            let counter = 0;
            requireInputs.forEach((input) => {
                if (!input.value) {
                    input.classList.add('is-error');
                    counter++;
                }

                // Если инпут заполнен, убираем еррор класс
                input.addEventListener('input', () => {
                    if (input.value.length >= 1) {
                        input.classList.remove('is-error');
                        input.classList.add('is-not-empty');
                    }
                });
            });

            // проверяем согласие
            if (argeeInput && argeeLabel) {
                if (!argeeInput.checked) {
                    argeeLabel.classList.add('is-error');
                    counter++;
                }
            }

            if (counter == 0) {
                // собираем все данные с формы и отправляем
                if (inputMrRobot) inputMrRobot.value = '';
                let formData: FormData;
                if (form.classList.contains('js-form-with-upload')) {
                    form.dispatchEvent(new Event('send'));

                    const inputFiles = form.querySelector<HTMLInputElement>('[name="filesId"]');

                    formData = new FormData(form);
                    formData.delete('filesId');

                    if (inputFiles) {
                        formData.set('files[]', inputFiles.value);
                    }
                } else {
                    formData = new FormData(form);
                }


                const messageThanks = messageContainer?.querySelector<HTMLElement>('.js-message-success');
                const messageError = messageContainer?.querySelector<HTMLElement>('.js-message-error');
                const loader = form.querySelector<HTMLElement>('.js-form-loader');
                const sendData = async () => {
                    if (urlForm) {
                        if (loader) loader.classList.add('is-show');
                        let response = await fetch(urlForm, {
                            method: 'POST',
                            body: formData,
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                if (data.success) {
                                    form.reset();

                                    inputsOfForm.forEach((input) => {
                                        input.classList.remove('is-not-empty');
                                    });
                                    if (messageThanks) {
                                        if (messageError) messageError.style.display = 'none';
                                        messageThanks.textContent = data.message;
                                        messageThanks.style.display = 'block';
                                    }

                                    clearForm();
                                    changeLink();
                                } else {
                                    if (messageError) {
                                        messageError.textContent = data.message;
                                        messageError.style.display = 'block';
                                    }
                                }
                            })
                            .catch(() => {
                                const err = new Error(`адрес ошибки: ${urlForm}, статус ошибки: ${response}`);
                                console.log(err);
                                if (messageError) messageError.style.display = 'block';
                            })
                            .finally(() => {
                                if (loader) loader.classList.remove('is-show');
                            });
                    }
                };

                sendData();

                // eslint-disable-next-line no-inner-declarations
                function clearForm() {
                    if (form.classList.contains('js-form-with-upload')) {
                        form.dispatchEvent(new Event('clear'));
                    }
                    setTimeout(() => {
                        unlockScroll();

                        if (messageThanks) {
                            messageThanks.style.display = 'none';
                        }
                        if (messageError) {
                            messageError.style.display = 'none';
                        }
                        const btnContactUs = document.querySelector('.js-contact-btn');
                        const btnForOpenform = document.querySelectorAll<HTMLElement>('[data-trigger-btn="form"]');
                        const formFooter = document.querySelectorAll<HTMLElement>('[data-content="form"]');

                        if (formContainer && formContainer.classList.contains('is-show')) {
                            formContainer.classList.remove('is-show');
                        }

                        if (btnContactUs && btnContactUs.classList.contains('is-active')) {
                            btnContactUs.classList.remove('is-active');
                        }

                        if (btnForOpenform.length > 0 && formFooter.length > 0) {
                            btnForOpenform.forEach((btn) => {
                                if (btn.classList.contains('is-active')) {
                                    btn.classList.remove('is-active');
                                }
                            });
                            formFooter.forEach((form) => {
                                if (form) {
                                    const formInner = form.querySelector<HTMLElement>('.js-popup-content');
                                    if (formInner) formInner.style.display = 'none';
                                    if (form.classList.contains('is-show')) {
                                        form.classList.remove('is-show');
                                    }
                                }
                            });
                        }

                        removeProductInput(form);
                    }, 3000);
                }

                // eslint-disable-next-line no-inner-declarations
                function changeLink() {
                    const popupLoginButton = document.querySelector('[data-popup-open="login"');
                    if (popupLoginButton?.classList.contains('is-active')) {
                        const url: any = popupLoginButton.getAttribute('href');
                        window.location.href = url;
                    }
                }
            }
        });
    });

    let phoneInput = document.querySelectorAll<HTMLElement>('[type="tel"]');
    phoneInput.forEach((phone) => {
        let currencyMask = IMask(phone, {
            mask: '+num',
            blocks: {
                num: {
                    mask: Number,
                },
            },
        });
    });
}
