export function makeSilicone(material, { roughnessMap, bumpMap }) {
    material.customUniforms = {
        uTime: { value: 0 },
    };
    material.metalness = 0.04;
    material.roughness = 0.4;
    material.clearcoat = 1;
    material.clearcoatRoughness = 0;
    material.thickness = 0.15;
    material.bumpMap = bumpMap;
    material.bumpScale = 0.005;
    material.ior = 1.5;
    material.reflectivity = 1;
    material.refractionRatio = 1;
    material.transmission = 0.95;
    if (material.normalMap) {
        material.normalMap.dispose();
        material.normalMap = null;
    }
    if (material.metalnessMap) {
        material.metalnessMap.dispose();
        material.metalnessMap = null;
    }
    if (material.roughnessMap) {
        material.roughnessMap.dispose();
        material.roughnessMap = null;
    }
    material.roughnessMap = roughnessMap;

    material.onBeforeCompile = (shader) => {
        shader.uniforms.uTime = material.customUniforms.uTime;
        shader.vertexShader = shader.vertexShader.replace(
            '#include <common>',
            `
                #include <common>
                varying vec3 vPositionW;
                varying vec3 vNormalW;

                uniform float uTime;
            `,
        );
        shader.vertexShader = shader.vertexShader.replace(
            '#include <fog_vertex>',
            `
                #include <fog_vertex>
                float mult = 0.5;
                mat4 rXPos = mat4(vec4(1.0,0.0,0.0,0.0),
                        vec4(0.0,cos(cameraPosition.x * mult),-sin(cameraPosition.x * mult),0.0),
                        vec4(0.0,sin(cameraPosition.x * mult),cos(cameraPosition.x * mult),0.0),
                        vec4(0.0,0.0,0.0,1.0));
                mat4 rYPos = mat4(vec4(cos(cameraPosition.y * mult),0.0,sin(cameraPosition.y * mult),0.0),
                        vec4(0.0,1.0,0.0,0.0),
                        vec4(-sin(cameraPosition.y * mult),0.0,cos(cameraPosition.y * mult),0.0),
                        vec4(0.0,0.0,0.0,1.0));
                mat4 rZPos = mat4(vec4(cos(cameraPosition.z * mult),-sin(cameraPosition.z * mult),0.0,0.0),
                        vec4(sin(cameraPosition.z * mult),cos(cameraPosition.z * mult),0.0,0.0),
                        vec4(0.0,0.0,1.0,0.0),
                        vec4(0.0,0.0,0.0,1.0));
                // vPositionW = vec3(vec4(position, 1.) * modelMatrix);
                vPositionW = vec3(vec4(position, 1.) * modelMatrix * rXPos * rYPos * rZPos);
                vNormalW = normalize(vec3(vec4(normal, 0.) * modelMatrix * rXPos * rYPos * rZPos ));
            `,
        );
        shader.fragmentShader = shader.fragmentShader.replace(
            '#include <common>',
            `
                #include <common>
                varying vec3 vPositionW;
                varying vec3 vNormalW;
            `,
        );
        shader.fragmentShader = shader.fragmentShader.replace(
            'gl_FragColor = vec4( outgoingLight, diffuseColor.a );',
            `
                vec3 viewDirectionW = normalize(cameraPosition - vPositionW);
                float fresnelTerm = dot(viewDirectionW, vNormalW);
                fresnelTerm = clamp(1. - fresnelTerm, 0., 1.);
                gl_FragColor = vec4(mix(outgoingLight, outgoingLight * vec3(0.031, 0.412, 0.682), (1. - fresnelTerm) * 0.5), diffuseColor.a);
                // gl_FragColor = vec4(vec3(1.) * fresnelTerm, diffuseColor.a);
            `,
        );
    };
}
