import * as Cookies from 'js-cookie';
import { lockScroll, unlockScroll } from '../custom-elements/lockScroll';

let closePopupTriggers: NodeListOf<HTMLElement>

const addCookie = () => {
    Cookies.set('COOKIES_NEW_SITE_POPUP', 'true', {
        expires: 365, // days
    });
}

function init() {
    const newSitePopup = document.querySelector('.js-new-site-popup');

    if (!newSitePopup) return;
    closePopupTriggers = newSitePopup.querySelectorAll('[data-popup-close]');

    if (Cookies.get('COOKIES_NEW_SITE_POPUP') !== 'true') {
        newSitePopup.classList.add('is-show');
        lockScroll();

        closePopupTriggers.forEach(btn => btn.addEventListener('click', addCookie));
        setTimeout(() => {
            newSitePopup.classList.remove('is-show');
            unlockScroll();
            addCookie();
        }, 10000);
    }
}

function destroy() {
    closePopupTriggers?.forEach(btn => btn.removeEventListener('click', addCookie));
}

const _module = { init, destroy };

export default _module;
