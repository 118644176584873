/* eslint-disable max-classes-per-file */
/* eslint-disable max-len */
/* eslint-disable max-lines */
/* global PUBLIC_PATH */
import { triggerCustomEvent } from '../../utils/trigger-events';
import * as THREE from 'three';
import { computeBoundsTree, disposeBoundsTree, acceleratedRaycast } from 'three-mesh-bvh';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { HDRCubeTextureLoader } from 'three/examples/jsm/loaders/HDRCubeTextureLoader';
import gsap from 'gsap';
import Model from './Model';
// import dat from 'three/examples/jsm/libs/dat.gui.module';
import debounce from 'lodash.debounce';

THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
THREE.Mesh.prototype.raycast = acceleratedRaycast;

const map = new WeakMap();
// const spherical = new THREE.Spherical();
// const startPos = new THREE.Vector3();
// const endPos = new THREE.Vector3();
// const axis = new THREE.Vector3();
// const tri = new THREE.Triangle();

class Scene {
    constructor(container, clearColor = 0xf6f8fa, isNeedGui) {
        this.render = this.render.bind(this);
        this.onResize = this.onResize.bind(this);
        this.onControlsChange = this.onControlsChange.bind(this);
        this.onTriggerRotateCamera = this.onTriggerRotateCamera.bind(this);
        this.container = container;
        this.canvas = this.container.querySelector('.js-canvas-model');
        this.canvasWrapper = this.container.querySelector('.js-canvas-wrapper');
        this.models = [];
        this.sizes = {
            width: this.canvasWrapper.offsetWidth,
            height: this.canvasWrapper.offsetHeight,
        };
        this.sceneReady = false;
        // this.gui = isNeedGui ? new dat.GUI() : undefined;

        this.state = {
            isInViewport: true,
        };

        this.animationDuration = 600;

        this.debouncedCancelRender = debounce(() => {
            this.state.isInViewport = false;
        }, 50);

        this.renderer = new THREE.WebGLRenderer({
            canvas: this.canvas,
            antialias: window.devicePixelRatio <= 2,
            powerPreference: 'high-performance',
        });
        this.renderer.outputEncoding = THREE.sRGBEncoding;
        this.renderer.physicallyCorrectLights = true;
        this.renderer.setSize(this.sizes.width, this.sizes.height);
        // this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 1.5));
        this.renderer.setClearColor(clearColor);

        this.scene = new THREE.Scene();
        this.scene.background = clearColor;

        this.textureLoader = new THREE.TextureLoader();

        this.camera = new THREE.PerspectiveCamera(25, this.sizes.width / this.sizes.height, 1, 100);
        // window.camera = this.camera;
        this.camera.position.z = 6;

        // this.raycaster = new THREE.Raycaster(undefined, undefined, 1, 75);
        this.raycaster = new THREE.Raycaster();
        this.raycaster.firstHitOnly = true;
        // this.raycaster.near = 1;
        // this.raycaster.far = 20;

        this.controls = new OrbitControls(this.camera, this.canvas);
        this.controls.target.set(0, -0.5, 0);
        this.controls.enablePan = false;
        this.controls.enableZoom = false;
        this.controls.enableDamping = true;
        this.controls.dampingFactor = 0.05;
        this.controls.addEventListener('change', this.onControlsChange);

        const pmremGenerator = new THREE.PMREMGenerator(this.renderer);

        const hdrCubeMap = new HDRCubeTextureLoader()
            .setPath(`${PUBLIC_PATH}img/maps/autoshop_01_1k_blur/`)
            .setDataType(THREE.UnsignedByteType)
            .load(['px.hdr', 'nx.hdr', 'py.hdr', 'ny.hdr', 'pz.hdr', 'nz.hdr'], () => {
                const hdrCubeRenderTarget = pmremGenerator.fromCubemap(hdrCubeMap);
                hdrCubeMap.magFilter = THREE.LinearFilter;
                hdrCubeMap.needsUpdate = true;
                this.scene.environment = hdrCubeRenderTarget.texture;
                pmremGenerator.dispose();
            });

        this.loadingManager = new THREE.LoadingManager((e) => {
            triggerCustomEvent(document, 'model.loaded');
            this.canvas.classList.add('is-loaded');
            this.onResize();
        });

        // this.rotationY = new THREE.Matrix4();
        // this.rotationX = new THREE.Matrix4();
        // this.translation = new THREE.Matrix4();
        // this.matrix = new THREE.Matrix4();
    }

    calculateSizes() {
        this.sizes.width = this.canvasWrapper.offsetWidth;
        this.sizes.height = this.canvasWrapper.offsetHeight;
    }

    updateCamera() {
        this.camera.aspect = this.sizes.width / this.sizes.height;
        this.camera.updateProjectionMatrix();
    }

    updateRenderer() {
        this.renderer.setSize(this.sizes.width, this.sizes.height);
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    }

    onResize() {
        this.calculateSizes();
        this.updateCamera();
        this.updateRenderer();
        this.renderer.render(this.scene, this.camera);
    }

    initModel(modelEl, setActive = true) {
        return new Promise((resolve, reject) => {
            const model = new Model(
                modelEl,
                this.state,
                this.loadingManager,
                this.textureLoader,
                this.renderer,
                this.scene,
                this.camera,
                this.sizes,
                this.animationDuration,
                this.raycaster,
                this.canvas,
                setActive,
                this.gui,
                this.onControlsChange,
                // this.controls,
            );
            this.models.push(model);
            map.set(modelEl, model);
            model
                .init()
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    }

    loadModel(modelEl) {
        const model = this.getModelByElement(modelEl);
        return new Promise((resolve, reject) => {
            if (!model.isLoaded) {
                model.load().then(() => {
                    requestAnimationFrame(() => {
                        this.renderer.render(this.scene, this.camera);
                    });
                    resolve();
                });
            } else {
                resolve();
            }
        });
    }

    showModel(modelEl) {
        const model = this.getModelByElement(modelEl);
        model.active = true;
        model.showModel();
    }

    hideModel(modelEl) {
        const model = this.getModelByElement(modelEl);
        model.active = false;
        model.hideModel();
    }

    hasModelInMap(element) {
        return map.has(element);
    }

    getModelByElement(element) {
        return map.get(element);
    }

    rotateCamera(coords, immediate = false) {
        const { x, y, z } = coords;
        // const proxy = { ...coords };
        // const proxy = new Vector3(x, y, z);
        gsap.to(this.camera.position, {
            x,
            y,
            z,
            duration: immediate ? 0 : this.animationDuration / 1000,
            ease: 'sine.inOut',
            onUpdate: this.onControlsChange,
        });

        // spherical.setFromVector3(proxy);
        // spherical.phi = 0;
        // spherical.makeSafe(); // important thing, see the docs for what it does
        // endPos.setFromSpherical(spherical);

        // startPos.copy(this.camera.position);

        // tri.set(endPos, this.scene.position, startPos);
        // tri.getNormal(axis);

        // const angle = startPos.angleTo(endPos);

        // const value = { value: 0 };

        // gsap.to(value, {
        //     value: 1,
        //     duration: this.animationDuration / 1000,
        //     ease: 'sine.inOut',
        //     onUpdate: () => {
        //         // this.camera.position.copy(target);
        //         // spherical.setFromVector3(proxy);
        //         // console.log(spherical, this.camera.position);
        //         // this.camera.position.x = proxy.x;
        //         // this.camera.position.y = proxy.y;
        //         // this.camera.position.z = proxy.z;
        //         this.camera.position.copy(startPos).applyAxisAngle(axis, angle * value.value);
        //     },
        // });
    }

    onTriggerRotateCamera(event) {
        this.rotateCamera(event.detail.coords, event.detail.immediate);
    }

    handlersInit() {
        window.addEventListener('resize', this.onResize);
        document.addEventListener('rotate.camera', this.onTriggerRotateCamera);
    }

    handlersDestroy() {
        window.removeEventListener('resize', this.onResize);
        document.removeEventListener('rotate.camera', this.onTriggerRotateCamera);
    }

    onControlsChange() {
        this.state.isInViewport = true;
        this.debouncedCancelRender();
    }

    render() {
        if (this.state.isInViewport) {
            this.controls.update();
            this.renderer.render(this.scene, this.camera);
        }

        this.rAF = requestAnimationFrame(this.render);
    }

    init() {
        this.handlersInit();
        this.render();
        this.state.isInViewport = false;
    }

    destroy() {
        cancelAnimationFrame(this.rAF);
        this.models.forEach((model) => {
            model.destroy();
        });
        this.models = [];
        this.handlersDestroy();
        this.controls.dispose();
        this.renderer.dispose();
    }
}

export function getInstanceByElement(element) {
    return element ? map.get(element) : undefined;
}

export default Scene;
