import * as Cookies from 'js-cookie';
import { Tabs } from './Tabs/Tabs';

export function registerCustomElements() {
    window.customElements.define('app-tabs', Tabs);

    if (Cookies.get('COOKIES_USAGE_ACCEPTED') !== 'true') {
        import(/* webpackChunkName: "CookiesAgreement" */ './CookiesAgreement/CookiesAgreement').then(
            ({ CookiesAgreement }) => {
                window.customElements.define('x-cookies-agreement', CookiesAgreement);
            },
        );
    }
}
