import { lockScroll, unlockScroll } from './lockScroll';

function showContentPopup(content: HTMLElement) {
    const popupContent = content.querySelector<HTMLElement>('.js-popup-content');
    if (popupContent) {
        popupContent.style.display = "flex";
    }
}

function closeContentPopup(content: HTMLElement) {
    const popupContent = content.querySelector<HTMLElement>('.js-popup-content');
    if (popupContent) {
        popupContent.style.display = "none";
    }
}

function createProductInput(value: string | undefined) {
    const input = document.createElement('input');
    input.setAttribute('name', 'product_name');
    if (value) input.setAttribute('value', value);
    input.setAttribute('hidden', '');
    return input;
}

function addProductInput (container: HTMLElement | Element) {
    const form = container?.querySelector('.js-form');
    const productName = document.querySelector<HTMLElement>('.js-product-name')?.dataset.name;
    if (form && productName) {
        const input = createProductInput(productName);
        form.prepend(input);
    }
}

function removeProductInput (container: HTMLElement | Element) {
    const form = container?.querySelector('.js-form');
    if (form) {
        const input = form.querySelector('[name="product_name"]');
        input?.remove();
    }
}

export default function togglePopup() {
    const btnOpen = document.querySelectorAll<HTMLElement>('[data-popup-open]');
    const btnClose = document.querySelectorAll('[data-popup-close]');
    const contents = document.querySelectorAll('[data-popup]');

    let contentPopup: HTMLElement | Element;

    btnOpen.forEach(btn => btn.addEventListener('click', (e) => {
        const nameBtn = btn.getAttribute('data-popup-open');
        if (btn.hasAttribute('href')) {
            e.preventDefault();
            // e.stopImmediatePropagation();
        }

        btnOpen.forEach((btn, i) => {
            if (btn !== e.target) {
                btn.classList.remove('is-active');
            }
        });

        if (!btn.classList.contains('js-not-close-popup')) {
            contents.forEach((content, i) => {
                content.classList.remove('is-show');
            });
        }

        document.addEventListener('keyup', (e) => {
            if (e.keyCode === 27) {
                if (contents.length > 0) {
                    contents.forEach((content, i) => {
                        content.classList.remove('is-show');
                        unlockScroll();
                    })
                }
            }
        })

        if (nameBtn) {
            findContent(nameBtn);
            if (btn.classList.contains('js-toggle-btn')) {
                if (btn.classList.contains('is-active')) {
                    contentPopup?.classList.remove('is-show');
                    btn.classList.remove('is-active');
                    closeContentPopup(contentPopup);
                    removeProductInput(contentPopup);
                } else {
                    contentPopup?.classList.add('is-show');
                    btn.classList.add('is-active');
                }
            } else {
                contentPopup?.classList.add('is-show');
                btn.classList.add('is-active');
                lockScroll();

                setTimeout(
                    showContentPopup
                    , 300, contentPopup
                )
            }
            if (contentPopup?.classList.contains('is-show')) {
                lockScroll();
                setTimeout(
                    showContentPopup
                    , 300, contentPopup
                )
            } else {
                unlockScroll();
                closeContentPopup(contentPopup);
                removeProductInput(contentPopup);
            }
        }

        if (btn.classList.contains('js-product-form-trigger')) {
            addProductInput(contentPopup);
        }
    }))

    btnClose.forEach(btn => btn.addEventListener('click', () => {
        const nameBtn = btn.getAttribute('data-popup-close');
        if (nameBtn) {
            findContent(nameBtn);
            contentPopup?.classList.remove('is-show');
            unlockScroll();
            if (btnOpen.length > 0)
                btnOpen.forEach(btn => btn.classList.remove('is-active'));

        }

        const form = contentPopup.querySelector('.js-form');
        if (form) {
            const inputsOfForm = form.querySelectorAll<Element | any>('.js-input');
            const argeeInput = form.querySelector<Element | any>('.js-agree-input');
            const argeeLabel = form.querySelector('.js-agree-label');

            if (argeeInput && argeeLabel && argeeLabel.classList.contains('is-error')) {
                argeeLabel.classList.remove('is-error');
            }

            inputsOfForm.forEach(input => {
                if (input) {
                    input.classList.remove('is-error');
                }
            });
        }
        removeProductInput(contentPopup);
    }))

    function findContent(nameBtn: string) {
        if (contents.length > 0) {
            contents.forEach((content, i) => {
                const name = content.getAttribute('data-popup');
                if (name === nameBtn) {
                    contentPopup = content;
                    return contentPopup;
                }
            })
        }
    }
}
