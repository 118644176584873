import gsap from 'gsap';

export default function headerAnimation() {

    const tl = gsap.timeline();

    tl.set(
        '.a-header-wrapper',
        {
            opacity: 1
        }
    )
        .set(
            '.a-header-item',
            {
                opacity: 1,
                x: 0
            },
            0
        );
}
