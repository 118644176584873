/* eslint-disable max-len */
import Swiper, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';
import zeroAdd from '../custom-elements/addZero';
import Scene from '../components/scene/index';
import activeCharacteristic from './solutionsChar';
import changeSlideCharacteristic from './solutionsSliderChar';

Swiper.use([Navigation]);

const map = new WeakMap<HTMLElement>();

let timerToShowModel: NodeJS.Timeout;
let scene: Scene | null;
let isSlideChanged = false;

function initSliderState(swiper: Swiper): void {
    swiper.slides.forEach((slide: Element | HTMLElement, i: number) => {
        if (slide instanceof HTMLElement) {
            slide.style.transform = `translate(-${i * 100}%, 0)`;
        }
    });
    swiper.slides[swiper.realIndex].classList.add('is-show');
}

function loadPrevNext(swiper: any, index: number): Promise<void> {
    return new Promise((resolve) => {
        if (index !== 0) {
            const prevSlideEl = swiper.slides[swiper.realIndex - 1];
            scene.loadModel(prevSlideEl).then(() => resolve());
        }
        if (index !== swiper.slides.length - 1) {
            const nextSlideEl = swiper.slides[swiper.realIndex + 1];
            scene.loadModel(nextSlideEl).then(() => resolve());
        }
    });
}

function changeSlide(swiper: Swiper, time: number) {
    swiper.allowSlideNext = false;
    swiper.allowSlidePrev = false;
    // swiper.allowTouchMove = false;

    swiper.slides[swiper.previousIndex].classList.add('is-out');

    setTimeout(() => {
        swiper.slides[swiper.previousIndex].classList.remove('is-show');
        swiper.slides[swiper.previousIndex].classList.remove('is-out');
    }, time / 4);

    setTimeout(() => {
        swiper.slides[swiper.realIndex].classList.add('is-in');
        swiper.slides[swiper.realIndex].classList.add('is-show');
    }, time / 2);

    setTimeout(() => {
        swiper.slides[swiper.realIndex].classList.remove('is-in');

        swiper.allowSlideNext = true;
        swiper.allowSlidePrev = true;
        // swiper.allowTouchMove = true;

        isSlideChanged = true;
    }, time);
}

function generateNav(container: HTMLElement) {
    const wrapperEl: HTMLElement | null = container.querySelector('.js-index-slider-controls');
    const navHtml = `
                    <div class="slider__toggle-block">
                        <div class="slider__counter">
                            <span class="js-slider-index-counter">01</span> /
                            <span class="js-slider-total-counter">03</span>
                        </div>
                        <div class="slider__buttons">
                            <button class="button slider__btn slider__btn--prev disable" aria-label="previous slide">
                                <span class="button__arrow">
                                    <svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.8889 1.05554L18 6.68518L11.8889 11.986" stroke="#0158A6" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
                                        <path d="M17.6685 6.52075L0.934278 6.52075" stroke="#0158A6" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                </span>
                            </button>
                            <button class="button slider__btn slider__btn--next" aria-label="next slide">
                                <span class="button__arrow">
                                    <svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.8889 1.05554L18 6.68518L11.8889 11.986" stroke="#0158A6" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
                                        <path d="M17.6685 6.52075L0.934278 6.52075" stroke="#0158A6" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                </span>
                            </button>
                        </div>
                    </div>
                    `;
    if (wrapperEl) {
        wrapperEl.innerHTML = navHtml;
    }
}

function init(container: HTMLElement | Document = document) {
    const sliderEl = container.querySelector<HTMLElement>('.js-index-slider');
    if (sliderEl) {
        const slides: Array<HTMLElement> = Array.from(sliderEl.querySelectorAll('.js-index-slider-slide'));
        if (slides.length > 1) {
            generateNav(sliderEl);

            const counterCurrentEl: HTMLElement | null = sliderEl.querySelector('.js-slider-index-counter');
            const counterTotalEl: HTMLElement | null = sliderEl.querySelector('.js-slider-total-counter');
            const btnPrev: HTMLElement | null = sliderEl.querySelector('.slider__btn--prev');
            const btnNext: HTMLElement | null = sliderEl.querySelector('.slider__btn--next');

            const slider: Swiper = new Swiper(sliderEl, {
                simulateTouch: false,
                noSwiping: true,
                allowTouchMove: false,
                speed: 800,
                slidePrevClass: 'is-prev',
                slideActiveClass: 'is-active',
                slideNextClass: 'is-next',
                virtualTranslate: true,
                init: false,
                navigation: {
                    nextEl: btnNext,
                    prevEl: btnPrev,
                    disabledClass: 'disable',
                },
                on: {
                    beforeInit: () => {
                        scene = new Scene(container, 0xf6f8fa, false);
                        scene.init();
                    },
                    init: () => {
                        const currentSlideEl = slider.slides[slider.realIndex];
                        initSliderState(slider);
                        if (counterCurrentEl && counterTotalEl) {
                            counterCurrentEl.textContent = zeroAdd(slider.realIndex + 1);
                            counterTotalEl.textContent = zeroAdd(slider.slides.length);
                        }
                        slider.slides.forEach((slide, i) => {
                            scene.initModel(slide, i === 0);
                        });
                        scene
                            .loadModel(currentSlideEl)
                            .then(() => {
                                scene.showModel(currentSlideEl);
                            })
                            .then(() => {
                                loadPrevNext(slider, slider.realIndex);
                            });
                    },
                    slideChangeTransitionStart: () => {
                        clearTimeout(timerToShowModel);
                        loadPrevNext(slider, slider.realIndex);
                        const currentSlideEl = slider.slides[slider.realIndex];
                        const prevSlideEl = slider.slides[slider.previousIndex];

                        if (counterCurrentEl && counterTotalEl) {
                            counterCurrentEl.textContent = zeroAdd(slider.realIndex + 1);
                            counterTotalEl.textContent = zeroAdd(slider.slides.length);
                        }
                        btnPrev?.setAttribute('disabled', '');
                        btnNext?.setAttribute('disabled', '');
                        scene.hideModel(prevSlideEl);
                        timerToShowModel = setTimeout(() => {
                            btnNext?.removeAttribute('disabled');
                            btnPrev?.removeAttribute('disabled');
                            activeCharacteristic.init();
                            scene.showModel(currentSlideEl);
                        }, 800);
                    },
                    slidePrevTransitionStart: () => {
                        changeSlide(slider, 800);
                        if (sliderEl.closest('.js-solutions-slide')) {
                            changeSlideCharacteristic(slider.previousIndex, slider.realIndex, 800);
                        }
                    },
                    slideNextTransitionStart: () => {
                        changeSlide(slider, 800);
                        if (sliderEl.closest('.js-solutions-slide')) {
                            changeSlideCharacteristic(slider.previousIndex, slider.realIndex, 800);
                        }
                    },
                },
            });

            slider.init();

            map.set(sliderEl, slider);
        } else {
            const slide = sliderEl.querySelector('.js-index-slider-slide');
            slide?.classList.add('is-show');
            scene = new Scene(container, 0xf6f8fa, false);
            scene.init();
            scene.initModel(slide);
            scene.loadModel(slide).then(() => {
                scene.showModel(slide);
            });
        }
    }
}

function destroy(container: HTMLElement | Document = document) {
    if (scene && scene.destroy) {
        scene.destroy();
        scene = null;
    }

    const sliderEl: HTMLElement | null = container.querySelector('.js-index-slider');
    if (sliderEl) {
        const slider = map.get(sliderEl);

        if (slider) {
            slider.destroy();
            map.delete(sliderEl);
        }
    }
}

const _module = { init, destroy };

export default _module;
