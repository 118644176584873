import gsap from 'gsap';

export default function headerAnimation() {

    const tl = gsap.timeline();

    tl.fromTo(
        '.a-header-wrapper',
        {
            duration: 0.3,
            opacity: 0,
        },
        {
            opacity: 1
        }
    )
        .fromTo(
            '.a-header-item',
            {
                duration: 0.6,
                opacity: 0,
                x: 20,
                stagger: 0.09,
            },
            {
                opacity: 1,
                x: 0
            },
            '-=0.5'
        );
}
