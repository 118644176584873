import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import zeroAdd from '../custom-elements/addZero';
import activeCharacteristic from './solutionsChar';
import changeSlideCharacteristic from './solutionsSliderChar';
import changeActiveCircle from './active-semicircles';

Swiper.use([Navigation, Pagination]);

const map = new WeakMap<HTMLElement, any>();

function init(container: HTMLElement | Document = document) {
    let sliderContainer = container.querySelectorAll<HTMLElement>('.js-swiper-slider');
    sliderContainer.forEach(slider => {
        let counterCurrent = slider.querySelector('.js-slider-index-counter'),
        counterTotal = slider.querySelector('.js-slider-total-counter');

        photoHeight(slider);

        let swifper = new Swiper(slider, {
            simulateTouch: false,
            noSwiping: true,
            allowTouchMove: false,
            speed: 800,
            slidePrevClass: 'is-prev',
            slideActiveClass: 'is-active',
            slideNextClass: 'is-next',
            virtualTranslate: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.slider__btn--next',
                prevEl: '.slider__btn--prev',
                disabledClass: 'disable',
            },
            on: {
                init: (swiper) => {
                    initSliderState(swiper);

                    if (counterCurrent && counterTotal) {
                        counterCurrent.textContent = zeroAdd(swiper.realIndex + 1);
                        counterTotal.textContent = zeroAdd(swiper.slides.length);
                    }
                    changeActiveCircle();
                },
                slideChangeTransitionStart: (swiper) => {
                    if (counterCurrent && counterTotal) {
                        counterCurrent.textContent = zeroAdd(swiper.realIndex + 1);
                        counterTotal.textContent = zeroAdd(swiper.slides.length);
                    }
                    slider.querySelector<HTMLElement>('.slider__btn--next')?.setAttribute('disabled', '');
                    slider.querySelector<HTMLElement>('.slider__btn--prev')?.setAttribute('disabled', '');
                    setTimeout(() => {
                        slider.querySelector<HTMLElement>('.slider__btn--next')?.removeAttribute('disabled');
                        slider.querySelector<HTMLElement>('.slider__btn--prev')?.removeAttribute('disabled');
                        activeCharacteristic.init();
                        changeActiveCircle();
                    }, 800);
                },
                slidePrevTransitionStart: (swiper) => {
                    changeSlide(swiper, 800);
                    changePhoto(slider, swiper.realIndex);
                    if (slider.closest('.js-solutions-slide')) {
                        changeSlideCharacteristic(swiper.previousIndex, swiper.realIndex, 800);
                    }
                },
                slideNextTransitionStart: (swiper) => {
                    changeSlide(swiper, 800);
                    changePhoto(slider, swiper.realIndex);
                    if (slider.closest('.js-solutions-slide')) {
                        changeSlideCharacteristic(swiper.previousIndex, swiper.realIndex, 800);
                    }
                }
            }
        });
    })

    // текстовый слайдер
    let sliderTextContainer = container.querySelectorAll<HTMLElement>('.js-swiper-text-slider');
    sliderTextContainer.forEach(slider => {

        let swiper = new Swiper(slider, {
            slidePrevClass: 'is-prev',
            slideActiveClass: 'is-active',
            slideNextClass: 'is-next',
            virtualTranslate: true,
            watchOverflow: true,
            pagination: {
                el: '.js-swiper-text-slider-bullets',
                type: 'bullets',
                clickable: true,
                currentClass: 'is-active',
                bulletClass: 'js-semicircle',
                bulletActiveClass: 'is-active',
                renderBullet: function (index, className) {
                    return `<span class="btn-semicircle__item ${className}">
                    <svg width="14px" height="7px" viewBox="0 0 14 7" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <!-- Generator: Sketch 58 (84663) - https://sketch.com -->
                        <title>Oval</title>
                        <desc>Created with Sketch.</desc>
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g transform="translate(-1862.000000, -887.000000)" fill="#9EAFBF">
                                <g transform="translate(1839.000000, 63.000000)">
                                    <g transform="translate(2.000000, 638.000000)">
                                        <g transform="translate(28.000000, 197.500000) rotate(90.000000) translate(-28.000000, -197.500000) translate(-8.000000, 190.000000)">
                                            <path d="M35,11 C35,7.13400675 31.8659932,4 28,4 C24.1340068,4 21,7.13400675 21,11" transform="translate(28.000000, 7.500000) rotate(90.000000) translate(-28.000000, -7.500000) "></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                            </span>`;
                },
            },
            on: {
                init: function () {
                    initSliderState(this);
                    changeActiveCircle();
                },
                slideChangeTransitionStart: function(){
                    setTimeout(() => {
                        changeActiveCircle();
                    }, 100);
                },
                slidePrevTransitionStart: function () {
                    changeSlide(this, 800);
                },
                slideNextTransitionStart: function () {
                    changeSlide(this, 800);
                }
            }
        });
    })

    // слайдер с табами-цифрами
    let sliderCounter: HTMLElement | null = container.querySelector('.js-slider-counter');
    if (sliderCounter) {
        var swiper = new Swiper(sliderCounter, {
            simulateTouch: false,
            noSwiping: true,
            allowTouchMove: false,
            slidePrevClass: 'is-prev',
            slideActiveClass: 'is-active',
            slideNextClass: 'is-next',
            virtualTranslate: true,
            pagination: {
              el: '.swiper-pagination',
              clickable: true,
              renderBullet: function (index, className) {
                return '<span class="main-news__tab-item ' + className + ' ">0' + (index + 1) + ' /</span>';
              },
            },
            on: {
                init: function () {
                    initSliderState(this);
                    photoHeightCounter(sliderCounter);
                },
                slidePrevTransitionStart: function () {
                    changeSlide(this, 100);
                    changePhoto(sliderCounter, this.realIndex);
                },
                slideNextTransitionStart: function () {
                    changeSlide(this, 600);
                    changePhoto(sliderCounter, this.realIndex);
                }
            }
        });
    }
}

function changeSlide(swiper: Swiper | any, time: number) {
    swiper.allowSlideNext = false;
    swiper.allowSlidePrev = false;
    swiper.allowTouchMove = false;

    const diretionSlide = swiper.previousIndex < swiper.realIndex ? 'next' : 'prev';

    swiper.slides[swiper.previousIndex].classList.add('is-out', `is-out-${diretionSlide}`);

    setTimeout(() => {
        swiper.slides[swiper.previousIndex].classList.remove('is-show');
        swiper.slides[swiper.previousIndex].classList.remove('is-out', 'is-out-prev', 'is-out-next');
    }, time / 4);

    setTimeout(() => {
        swiper.slides[swiper.realIndex].classList.add('is-in', `is-in-${diretionSlide}`);
        swiper.slides[swiper.realIndex].classList.add('is-show');
    }, time / 2);

    setTimeout(() => {
        swiper.slides[swiper.realIndex].classList.remove('is-in', 'is-in-prev', 'is-in-next');

        swiper.allowSlideNext = true;
        swiper.allowSlidePrev = true;
        swiper.allowTouchMove = true;
    }, time);
}

function changePhoto(container: HTMLElement, index: number) {
    const photoList = container.querySelector<HTMLElement>('.js-slide-photo-list');
    if (photoList) {
        const photo = photoList.querySelectorAll('.js-slide-photo');
        photoList.style.transform = 'translateY(-' + (100 / photo.length) * index + '%)';
    }
}

function photoHeight(container: HTMLElement) {
    const photoContainer = container.querySelector<HTMLElement>('.js-slide-photo-container');

    if (photoContainer) {
        const photoContainerHeight = photoContainer.offsetHeight;
        const photoList = photoContainer.querySelectorAll<Element | any>('.js-slide-photo');

        photoList.forEach(photo => {
            photo.style.height = photoContainerHeight + 'px';
        });
    }
}

function photoHeightCounter(container: HTMLElement) {
    const photoContainer = document.querySelector<HTMLElement>('.js-slider-counter');

    if (photoContainer) {
        const photoContainerHeight = photoContainer.offsetHeight;
        const photoList = photoContainer.querySelectorAll<Element | any>('.js-slide-photo');

        photoList.forEach(photo => {
            photo.style.height = photoContainerHeight + 'px';
        });
    }
}

function initSliderState(swiper: any) {
    for (var i = 0; i < swiper.slides.length; i++) {
        swiper.slides[i].style.transform = 'translate(-' + i * 100 + '%, 0)';
    }

    if (swiper.slides && swiper.slides[swiper.realIndex]) {
        swiper.slides[swiper.realIndex].classList.add('is-show');
    }
}


function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-swiper-slider')).forEach((el) => {
        const slider = map.get(el);

        if (slider) {
            slider.destroy();
            map.delete(el);
        }
    });
    Array.from(container.querySelectorAll<HTMLElement>('.js-swiper-text-slider')).forEach((el) => {
        const slider = map.get(el);

        if (slider) {
            slider.destroy();
            map.delete(el);
        }
    });
    Array.from(container.querySelectorAll<HTMLElement>('.js-slider-counter')).forEach((el) => {
        const slider = map.get(el);

        if (slider) {
            slider.destroy();
            map.delete(el);
        }
    });
}

const _module = { init, destroy };

export default _module;
