function exit() {
    setTimeout(() => {
        location.reload();
    }, 100);
}

function init(container: HTMLElement | Document = document) {
    const backLink = document.querySelectorAll<HTMLElement>('[data-exit-partner]');
    backLink.forEach(link => {
        link.addEventListener('click', exit);
    })
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('[data-exit-partner]')).forEach((el) => {
        el.addEventListener('click', exit);
    });
}

const _module = { init, destroy };

export default _module;
